import { template as template_7d076ff16c38489eb1522d0a63e9acff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_7d076ff16c38489eb1522d0a63e9acff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
