import { template as template_6690f6c14336435bb7f0b57b08e0901e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6690f6c14336435bb7f0b57b08e0901e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
