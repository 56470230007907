import { template as template_c71944d3ca074e28849d227f2042f002 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c71944d3ca074e28849d227f2042f002(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
