import { template as template_638bb2f2f5c947bb899b5fcc2b16381d } from "@ember/template-compiler";
const WelcomeHeader = template_638bb2f2f5c947bb899b5fcc2b16381d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
